import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import banner from '../../assets/banner.jpg'
import logo from '../../assets/banner-logo.png'
import "./failure.css"
import { Link } from 'react-router-dom'

const Failure = () => {
    return (
        <div id='failure-container'>

            <div id='failure-container-inner' className='white bg-blue'>

                <div id='failure-banner'>
                    <h1 className='h0'>404 error</h1>
                </div>

                <div id='failure-content'>

                    <div>
                        <h1 className='h1 more'>Oops! We've hit a snag!</h1>
                        <FontAwesomeIcon icon={faCircleCheck} size="1x" id='failure-icon' className='mobile-hide' />
                    </div>
                    <p className='h2'>Sorry for the inconvenience! Please try again in about an hour or reach out to our support team at <a href="#" className='light-blue bold'>support@motobookie.com</a></p>
                    <div>
                        <Link to={'/'} className="white">Try again</Link>
                    </div>

                </div>
            </div>



        </div>
    )
}

export default Failure
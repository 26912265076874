import React, { useEffect } from 'react';
import './App.css';
import { createUser, isAccessable } from './services/user.services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faPerson, faSackDollar } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faBank } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import logo from "./assets/favicon.svg"
import { faStripe } from '@fortawesome/free-brands-svg-icons'
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom";
import { writeUserData } from './services/firebase.user.services';

const App = () => {

  const navigate = useNavigate()

  const [data, setData] = useState({
    chips: '',
    name: '',
    username: '',
    email: '',
    bankname: '',
    routing: '',
    accountnumber: '',
    actionneeded: 0,
    accounttype: 0
  })

  const [isLoading, setIsLoading] = useState(true)

  const [bools, setBools] = useState([false, false, false, false, false, false, false])

  const [error, setErrors] = useState([false, false, false, false, false, false, false])

  const [readyToSubmit, setReadyToSubmit] = useState(false)

  const checkSubmit = () => {
    const temp = Object.values(data).indexOf('') < 0 || Object.values(data).indexOf(0) < 0
    return (!error.includes(true) && !temp)
  }

  const setInputError = (id) => {
    let temp = [...error]
    temp[id] = true
    setErrors(temp)
  }


  const cleanInputError = (id) => {
    let temp = [...error]
    temp[id] = false
    setErrors(temp)
  }

  const inputHandler = (e) => {
    const numberonly = /^$|^[0-9\b]+$/

      ; // Allow only numbers and backspace
    if (e.target.name === 'chips') {

      if (numberonly.test(e.target.value)) {
        let temp = [...error]
        temp[1] = false
        temp[6] = false
        temp[5] = false
        setErrors(temp)
        setData({
          ...data,
          [e.target.name]: e.target.value
        })
      }
      else {
        setInputError(5)
      }

    }
    else if (e.target.name === 'name' || e.target.name === 'email' || e.target.name === 'username' || e.target.name === 'bankname') {
      setData({
        ...data,
        [e.target.name]: e.target.value
      })
      cleanInputError(2)
    }

    else if (e.target.name === 'routing' || e.target.name === 'accountnumber') {

      if (numberonly.test(e.target.value)) {
        setData({
          ...data,
          [e.target.name]: e.target.value
        })
        cleanInputError(3)
      }
      else {
        setInputError(3)
      }

    }

  }

  const focusHandler = (id) => {
    let temp = [...bools]
    temp[id] = !bools[id]
    setBools(temp)

    /*if (data.name === '' && bools[id]) {
      setInputError(2)
    }*/

  }

  const blurHandler = (name, errorid, id) => {

    if (name === 'chips' && data[name] < 5) {
      setInputError(6)
    }

    if (name === 'accountnumber' || name === 'routing') {
      cleanInputError(3)
    }

    if (data[name] === '' && bools[id]) {
      setInputError(errorid)
    }
    let temp = [...bools]
    temp[id] = !bools[id]
    setBools(temp)
  }

  const buttonHandler = (name, value) => {

    if (name === "actionneeded" && value === 2) {
      setData({
        chips: 0,
        name: '',
        username: '',
        email: '',
        bankname: '',
        routing: '',
        accountnumber: '',
        actionneeded: value,
        accounttype: 0
      })
    }
    else if (name === "actionneeded" && value === 1) {
      setData({
        chips: '',
        name: '',
        username: '',
        email: '',
        bankname: '',
        routing: '',
        accountnumber: '',
        actionneeded: value,
        accounttype: 0
      })
    }
    else {
      setData({
        ...data,
        [name]: value
        // add other properties and their new values as needed
      })
    }

  }

  const createNewUser = (user) => {

    const result = writeUserData(data)
    navigate('/success')

  }
  // <button onClick={() => createNewUser()}>Create User</button>

  const isAccessableFront = () => {

    setTimeout(function () {
      setIsLoading(false)
    }, 1000);

  }

  useEffect(() => {
    isAccessableFront()
  }, [])

  return (

    <div id='py-container'>

      {
        isLoading ?

          <motion.img
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity }}
            src={logo}
            id="loader" />


          : <div id="py-form">

            <div className='form-row'>
              <div className='row'>

                <div className='form-row-header'>
                  <h2 className='h2 white'>Action needed</h2>
                  <p className={error[0] ? 'show error sub' : 'hide'}>Please select what action you are performing</p>
                </div>

              </div>

              <div className='row'>


                <div className='button-container'>

                  <button onClick={() => buttonHandler('actionneeded', 1)} className={`button bg-blue border-blue hoverable white h2 ${data.actionneeded === 1 ? 'active' : 'inactive'}`}>Cashing out chips</button>
                  <button onClick={() => buttonHandler('actionneeded', 2)} className={`button bg-blue border-blue hoverable white h2 ${data.actionneeded === 2 ? 'active' : 'inactive'}`}>Changing bank information</button>

                </div>

              </div>
            </div>

            {
              data.actionneeded != 0 ?
                <>
                  <div className='form-row'>

                    <div className='row'>
                      <div className='form-row-header'>
                        <h2 className='h2 white'>Please add in your Motobookie Information</h2>
                        <p className='sub gray'>Our team will use this information to verify your identity</p>
                        <p className={error[2] ? 'show error sub normal' : 'hide'}>Please make sure all fields are filled with your Motobookie credentials</p>
                      </div>
                    </div>

                    <div className='row'>


                      <div className={`input-container transition bg-blue ${bools[1] ? "border-light-blue" : "border-transparent"}`}>
                        <div className='content gray'>
                          <p className='sub normal'>Full Name</p>
                          <input
                            onBlur={() => blurHandler('name', 2, 1)}
                            onFocus={() => focusHandler(1)}
                            value={data.name}
                            onChange={inputHandler}
                            name='name'
                            // onBlur={() => focusHandler()}
                            // onFocus={() => focusHandler()}
                            className='input h2 bg-transparent border-none white bold'
                            placeholder='John Doe'
                          />
                        </div>
                        <div className='icon'>
                          <FontAwesomeIcon icon={faPerson} className="white img mobile-hide" />
                        </div>
                      </div>

                    </div>

                    <div className='row'>

                      <div className={`input-container transition bg-blue ${bools[2] ? "border-light-blue" : "border-transparent"}`}>
                        <div className='content gray'>
                          <p className='sub normal'>Motobookie Email</p>
                          <input
                            onBlur={() => blurHandler('email', 2, 2)}
                            onFocus={() => focusHandler(2)}
                            value={data.email}
                            onChange={inputHandler}
                            name='email'
                            // onBlur={() => focusHandler()}
                            // onFocus={() => focusHandler()}
                            className='input h2 bg-transparent border-none white bold'
                            placeholder='john@doe.com'
                          />
                        </div>
                        <div className='icon'>
                          <FontAwesomeIcon icon={faMailBulk} className="white img mobile-hide" />
                        </div>
                      </div>

                      <div className={`input-container transition bg-blue ${bools[3] ? "border-light-blue" : "border-transparent"}`}>
                        <div className='content gray'>
                          <p className='sub normal'>Motobookie Username</p>

                          <input

                            onBlur={() => blurHandler('username', 2, 3)}
                            onFocus={() => focusHandler(3)}
                            value={data.username}
                            onChange={inputHandler}
                            name='username'
                            // onBlur={() => focusHandler()}
                            // onFocus={() => focusHandler()}
                            className='input h2 bg-transparent border-none white bold'
                            placeholder='johndoe123'
                          />
                        </div>
                        <div className='icon'>
                          <FontAwesomeIcon icon={faUser} className="white img mobile-hide" />
                        </div>
                      </div>

                    </div>
                  </div>

                  {
                    data.actionneeded === 1 ?
                      <div className='form-row'>

                        <div className='row'>
                          <div className='form-row-header'>
                            <h2 className='h2 white'>Please enter the amount of chips you'd like to withdraw</h2>
                            <p className='sub gray'>Must be more than 5 chips.</p>
                            <p className={error[5] ? 'show error sub bold' : 'hide'}>Only numbers are allowed in this field</p>
                            <p className={error[6] ? 'show error sub bold' : 'hide'}>You can only withdraw more than 5 chips</p>
                            <p className={error[1] ? 'show error sub bold' : 'hide'}>Please make fill out the amount of chips you'd like to withdraw</p>
                          </div>
                        </div>

                        <div className='row'>


                          <div className={`input-container transition bg-blue ${bools[0] ? "border-light-blue" : "border-transparent"}`}>
                            <div className='content gray'>
                              <p className='sub normal'>Amount of chips to withdraw</p>
                              <input
                                type='num'
                                onBlur={() => blurHandler('chips', 1, 0)}
                                onFocus={() => focusHandler(0)}
                                value={data.chips}
                                onChange={inputHandler}
                                name='chips'
                                // onBlur={() => focusHandler()}
                                // onFocus={() => focusHandler()}
                                className='input h2 bg-transparent border-none white bold'
                                placeholder='5'
                              />
                            </div>
                            <div className='icon'>
                              <FontAwesomeIcon icon={faSackDollar} className="white img mobile-hide" />
                            </div>
                          </div>

                        </div>
                      </div>
                      : ''
                  }
                  <div className='form-row'>

                    <div className='row'>

                      <div className='form-row-header'>
                        <h2 className='h2 white'>Add in your banking information</h2>
                        <p className='sub gray'>By placing your credit card information we are subject <br />to charging your card the chosen subscription fee once a month.</p>
                        <p className={error[3] ? 'show error sub normal' : 'hide'}>Please make sure all fields are filled out correctly</p>
                        <p className={error[3] ? 'show error sub normal' : 'hide'}>Please make sure to only put numbers in routing and accounting inputs</p>
                      </div>

                    </div>


                    <div className='row'>

                      <div className={`input-container transition bg-blue ${bools[4] ? "border-light-blue" : "border-transparent"}`}>
                        <div className='content gray'>
                          <p className='sub normal'>Name of your bank</p>
                          <input
                            onBlur={() => blurHandler('bankname', 3, 4)}
                            onFocus={() => focusHandler(4)}
                            value={data.bankname}
                            onChange={inputHandler}
                            name='bankname'
                            // onBlur={() => focusHandler()}
                            // onFocus={() => focusHandler()}
                            className='input h2 bg-transparent border-none white bold'
                            placeholder='Wells Fargo'
                          />
                        </div>
                        <div className='icon'>
                          <FontAwesomeIcon icon={faBank} className="white img mobile-hide" />
                        </div>
                      </div>

                    </div>

                    <div className='row'>
                      <div className={`input-container transition bg-blue ${bools[5] ? "border-light-blue" : "border-transparent"}`}>
                        <div className='content gray'>
                          <p className='sub normal'>Routing Number</p>
                          <input

                            onBlur={() => blurHandler('routing', 3, 5)}
                            onFocus={() => focusHandler(5)}
                            value={data.routing}
                            onChange={inputHandler}
                            name='routing'
                            // onBlur={() => focusHandler()}
                            // onFocus={() => focusHandler()}
                            className='input h2 bg-transparent border-none white bold'
                            placeholder="1234 1234 1234 1234"
                          />
                        </div>
                        <div className='icon'>
                          <FontAwesomeIcon icon={faBank} className="white img" />
                        </div>
                      </div>


                      <div className={`input-container transition bg-blue ${bools[6] ? "border-light-blue" : "border-transparent"}`}>
                        <div className='content gray'>
                          <p className='sub normal'>Accounting Number</p>
                          <input
                            onBlur={() => blurHandler('accountnumber', 3, 6)}
                            onFocus={() => focusHandler(6)}
                            value={data.accountnumber}
                            onChange={inputHandler}
                            name='accountnumber'
                            className='input h2 bg-transparent border-none white bold'
                            placeholder="1234 1234 1234 1234"
                          />
                        </div>
                        <div className='icon'>
                          <FontAwesomeIcon icon={faBank} className="white img mobile-hide" />
                        </div>
                      </div>

                    </div>

                  </div>

                  <div className='form-row'>
                    <div className='row'>
                      <div className='form-row-header'>
                        <h2 className='h2 white'>Account type</h2>
                        <p className={error[4] ? 'show error sub' : 'hide'}>Please make sure to select whether this account is a Checkings or Savings account</p>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='button-container'>
                        <button onClick={() => buttonHandler('accounttype', 1)} className={`button bg-blue border-blue hoverable white h2 ${data.accounttype === 1 ? 'active' : 'inactive'}`}>Checkings</button>
                        <button onClick={() => buttonHandler('accounttype', 2)} className={`button bg-blue border-blue hoverable white h2 ${data.accounttype === 2 ? 'active' : 'inactive'}`}>Savings</button>
                      </div>
                    </div>

                  </div>

                  <div className='form-row'>
                    <div className='row'>
                      <div className='divider'></div>
                    </div>
                  </div>

                  <div className='form-row'>
                    <div className='row'>

                      <div id="stripe-container" className='white'>

                        <div>
                          <FontAwesomeIcon icon={faLock} size="1x" className='mobile-hide' />
                          <p className='sub'>Guaranteed safe & secure</p>
                        </div>

                        <div className='stripe-box bg-white dark-blue'>
                          <p className='sub normal'>powered by</p>
                          <FontAwesomeIcon icon={faStripe} size="2x" />
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='form-row'>
                    <div className='row'>
                      <div className='divider'></div>
                    </div>
                  </div>


                  <div className='form-row'>
                    <button className='bg-blue white normal border-blue button-2 h2'
                      onClick={() => createNewUser()}
                      //error.includes(true) && !(Object.values(data).indexOf('') < 0) && !(Object.values(data).indexOf(0) < 0)
                      disabled={error.includes(true) || (!(Object.values(data).indexOf('') < 0) || (data.actionneeded === 1 ? !(Object.values(data).indexOf(0) < 0) : (data.accounttype === 0)))}>Submit</button>
                  </div> </> : ''
            }

          </div >
      }

    </div >

  );
}

export default App;

import db from "../config/firebase"
import { getDatabase, ref, child, push, update } from "firebase/database";

export function writeUserData(user) {
    const db = getDatabase();
    // Get a key for a new Post.
    const newPostKey = push(child(ref(db), 'users/')).key;
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};
    updates['/users/' + newPostKey] = user;
    update(ref(db), updates).then(response => {
        return true
    }).catch(error => {
        return false
    });;
}
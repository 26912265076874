import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import banner from '../../assets/banner.jpg'
import logo from '../../assets/banner-logo.png'
import "./success.css"

const Success = () => {
    return (
        <div id='success-container'>

            <div id='success-container-inner' className='white bg-blue'>

                <div id='success-banner'>
                    <img src={logo}/>
                </div>

                <div id='success-content'>

                    <div>
                        <h1 className='h1 more'>Your request has been submitted!</h1>
                        <FontAwesomeIcon icon={faCircleCheck} size="1x" id='success-icon' className='mobile-hide'/>
                    </div>
                    <p className='h2'>Our team will verify the information you have sent and will act accordingly. This process can take anywhere between <strong><em>3 to 5 business days.</em></strong> If our team is unable to verify your information we will reach out to you via the email you provided.</p>
                    <p className='h2'> If you requested to withdrawl chips, once verified, a wire transfer will be sent to the account information you provided.</p>
                    <p className='h2'> If you have any questions or concerns please feel free to reach out to our support team at <a href='#' className='light-blue bold'>support@motobookie.com</a></p>
                    <div>
                        <a href='https://www.motobookie.com' className='white'>Go to Motobookie Website</a>
                    </div>

                </div>
            </div>



        </div>
    )
}

export default Success